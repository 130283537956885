import { useToast } from '@chakra-ui/react'
import React from 'react';
import {IoMdCopy} from 'react-icons/io'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { TfiWrite } from "react-icons/tfi";


const Address = () => {
  const toast = useToast();

  function CopyAlert(){
    toast({title: 'Хууллаа',status: 'success',duration: 3000,isClosable: true,})
  }

  return (
    <div className='font-Roboto text-white mt-8 w-full'>
      <div>
          <h1 className='font-bold text-md uppercase text-xl'>Хаяг холбох</h1>
          <p>Address</p>
      </div>

      <div className='mt-8 bg-[#4747b3] w-full p-8 rounded-md'>
        <h1 className='text-2xl'>Эрээн хот (Сансар салбар)</h1>
        <p className='text-gray-300 text-sm'>Эрээн хотын агуулах хаяг</p>

        <div className='mx-2 w-full'>
            <div className='grid w-full justify-center text-sm'>

                <div className='mt-4'>
                    <h1>收件人 (Хүлээн авагч)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>Өөрийн нэр утасны дугаар</h1>
                        </div>
                        <CopyToClipboard text="Өөрийн нэр утасны дугаар">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                {/* <IoMdCopy size={23} /> */}
                                {/* <h1 className=''>copy</h1> */}
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>电话 (Утас)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>电话: 13718589697</h1>
                        </div>
                        <CopyToClipboard text="电话: 13718589697">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>所在地区 (Бүс нутаг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>内蒙古自治区 锡林郭勒盟 二连浩特市 二连浩特社区建设管理局</h1>
                        </div>
                        <CopyToClipboard text="内蒙古自治区 锡林郭勒盟 二连浩特市 二连浩特社区建设管理局">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>街道地址 (Хаяг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>利众国际物流园区B-8号-赛音,(утсаа давтаж бичнэ)</h1>
                        </div>
                        <CopyToClipboard text="利众国际物流园区B-9号-赛音,(утсаа давтаж бичнэ)">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>邮编 (Зип код)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>011000</h1>
                        </div>
                        <CopyToClipboard text="011000">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>

            </div>
        </div>

      </div>

      <div className='mt-8 bg-[#4747b3] w-full p-8 rounded-md'>
        <h1 className='text-2xl'>Эрээн хот (10-р хороолол салбар)</h1>
        <p className='text-gray-300 text-sm'>Эрээн хотын агуулах хаяг</p>

        <div className='mx-2 w-full'>
            <div className='grid w-full justify-center text-sm'>

                <div className='mt-4'>
                    <h1>收件人 (Хүлээн авагч)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>Өөрийн нэр утасны дугаар</h1>
                        </div>
                        <CopyToClipboard text="Өөрийн нэр утасны дугаар">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                {/* <IoMdCopy size={23} /> */}
                                {/* <h1 className=''>copy</h1> */}
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>电话 (Утас)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>13947396550</h1>
                        </div>
                        <CopyToClipboard text="13947396550">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>所在地区 (Бүс нутаг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>内蒙古自治区
锡林郭勒盟
二连浩特市
二连浩特社区建设管理局</h1>
                        </div>
                        <CopyToClipboard text="内蒙古自治区
锡林郭勒盟
二连浩特市
二连浩特社区建设管理局">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>街道地址 (Хаяг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>利众物流园区B-8号, 宝乐尔(утсаа бичих)</h1>
                        </div>
                        <CopyToClipboard text="利众物流园区B-8号, 宝乐尔(утсаа бичих)">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>邮编 (Зип код)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-52 bg-[#4242b3] rounded-md py-1 px-2'>
                          <h1>011000</h1>
                        </div>
                        <CopyToClipboard text="011000">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>

            </div>
        </div>

      </div>
      
    </div>
  )
}

export default Address